exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-pete-newbon-tsx": () => import("./../../../src/pages/pete-newbon.tsx" /* webpackChunkName: "component---src-pages-pete-newbon-tsx" */),
  "component---src-pages-report-antisemitism-js": () => import("./../../../src/pages/report-antisemitism.js" /* webpackChunkName: "component---src-pages-report-antisemitism-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-cookies-page-js": () => import("./../../../src/templates/cookies-page.js" /* webpackChunkName: "component---src-templates-cookies-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-news-page-js": () => import("./../../../src/templates/news-page.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-press-page-js": () => import("./../../../src/templates/press-page.js" /* webpackChunkName: "component---src-templates-press-page-js" */),
  "component---src-templates-privacy-page-js": () => import("./../../../src/templates/privacy-page.js" /* webpackChunkName: "component---src-templates-privacy-page-js" */),
  "component---src-templates-reports-page-js": () => import("./../../../src/templates/reports-page.js" /* webpackChunkName: "component---src-templates-reports-page-js" */),
  "component---src-templates-reports-post-js": () => import("./../../../src/templates/reports-post.js" /* webpackChunkName: "component---src-templates-reports-post-js" */),
  "component---src-templates-terms-page-js": () => import("./../../../src/templates/terms-page.js" /* webpackChunkName: "component---src-templates-terms-page-js" */),
  "component---src-templates-testimony-page-js": () => import("./../../../src/templates/testimony-page.js" /* webpackChunkName: "component---src-templates-testimony-page-js" */),
  "component---src-templates-testimony-post-js": () => import("./../../../src/templates/testimony-post.js" /* webpackChunkName: "component---src-templates-testimony-post-js" */)
}

